@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/breakpoints.scss';

$cta_position: -60px;
$hover-transition: .6s;

.projects {
  position: relative;
  z-index: 1;

  .huge-text {
    top: 25%;
  }

  ul {
    @include unstyle-list;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint('tablet') {
      align-items: flex-start;
    }
  }
}

.project-wrapper {
  position: relative;
  max-width: 500px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  
  &:not(:last-child) {
    margin-bottom: 7%;
  }

  // projects corner elements
  @include breakpoint('tablet') {
    .corner-decoration {
      transition: 0.3s ease-in;
      position: absolute;
      content: '';
      background: $text;

      &:first-child {
        height: 20%;
        width: 1px;
        transform-origin: top;
      }

      &:nth-child(2) {
        transform-origin: right;
        height: 1px;
        width: 20%;
      }
    }
  }

  @include breakpoint('fablet') {
    &::before,
    &::after {
      opacity: 1;
    }
  }

  @include breakpoint('wide') {
    max-width: 550px;
  }


  // Styling even and odd projects

  &:nth-child(even) {
    .corner-decoration {
      right: -30px;
      top: -30px;
    }

    @include breakpoint('fablet') {
      align-self: flex-end;
    }

    .project--cta {
      @include breakpoint('desktop') {
        transform: rotate(90deg);
        transform-origin: center;
      }
    }
    .cover {
      background: $dot;
    }
  }

  &:nth-child(odd) {
    .corner-decoration{
      left: -30px;
      top: -30px;
    }

    @include breakpoint('fablet') {
      align-self: flex-start;
    }

    .cover {
      background: $text;
    }
  }

  // Hover styling
  &:hover {
    .project {
      opacity: 0.4;
      transform: scale(0.9);

       &:after {
         opacity: 1;
       }
    }

   .project--image {
     transform: scale(1.2) !important;
   }
  
   .project--cta {
     opacity: 1;
     pointer-events: none;

     .project--cta-text {
       opacity: 1 !important;
       transform: translateY(0);
     }

     .arrow {
       transform: translateX(10px);
     }
   }
 }

  .project {
    height: 100%;
    display: block;
    position: relative;
    transition: $hover-transition ease-out;
    overflow: hidden;
  }

  .project--image {
    transition: $hover-transition ease-out;
    padding-top: 65%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: top;
  }

  .project--cta {
    background-color: rgba(100, 100, 100, 0.8);
    padding: 25px 80px;
    color: white;
    position: absolute;
    top: 20px;
    transition: $hover-transition;
    letter-spacing: 5px;
    right: $cta_position;
    opacity: 0.5;
    display: none;

    @include breakpoint('fablet') {
      display: inline-block;
    }

    .project--cta-text {
      transition: $hover-transition;
      opacity: 0;
      transform: translateY(10px);
      display: block;
    }

    .arrow {
      display: inline-block;
      transition: $hover-transition;
      transition-delay: 0.1s;
    }
  }
}

.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
}
