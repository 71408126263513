@import '../../assets/styles/variables.scss';
@import '../../assets/styles/breakpoints.scss';

$swiper-transition: .4s;

#testimonials {
  position: relative;
}

#testimonials__slider { 
  max-width: 800px;
}

.testimonial {
  margin: 0 auto;
  text-align: center;
}

.testimonial__content {
  line-height: 1.5;
  font-size: 14px;
  margin-top: 10px;
  background-color: $background-color;
  position: relative;

  .quote {
    position: absolute;
    color: $dot;
    left: 10px;
    top: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 50px;
    line-height: 1;
    
    @include breakpoint('tablet') {
      font-size: 100px;
    }
  }
  
  blockquote {
    position: relative;
    border: 1px solid;
    text-align: left;
    padding: 30px;
    margin: 0;
    
    @include breakpoint('tablet') {
      padding: 30px 30px 30px 60px;
    }

    span {
      position: absolute;
    }

    .testimonial__decoration__wave {
      content: '';
      position: absolute;
      border: 1px solid $text;
      border-radius: 0 50px 0 0;
      width: 60px;
      height: 60px;
      bottom: -61px;
      left: 50px;
      border-bottom: none;
      border-left: none;
      z-index: 3; 
    }

    .testimonial__decoration__hide {
      content: '';
      position: absolute;
      width: 80px;
      border: 6px solid $background-color;
      bottom: -3px;
      left: 50px;
      z-index: 2;
    }
  }
}

.testimonial__author {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: left;
  line-height: 1.4;
  margin-top: 10px;

  &__title {
    font-style: italic;
  } 
  
  @include breakpoint('fablet') {
    justify-content: center;
  }
  
  p {
    margin: 0;
  }

  &__details {
    width: 62%;

    @include breakpoint('fablet') {
      width: auto;
    }
  }
}

.testimonial__avatar {
  border-radius: 50%;
  width: 20%;
  max-width: 70px;
  margin-right: 40px;

  @include breakpoint('fablet') {
    width: auto;
    margin-right: 20px;
  }
}