@import '../breakpoints.scss';
@import '../variables.scss';

.swiper-wrapper {
  padding-bottom: 60px;

}
.swiper-button-next,
.swiper-button-prev {
  color: white !important;
  top: auto !important;
  bottom: 0px !important;
  text-align: center;
  display: none !important;
  height: 40px !important;
  width: 40px !important;
  transition: .3s ease-out;

  @include breakpoint('desktop') {
    display: block !important;
  }
  
  &::after,
  &::before {
    font-size: 14px !important;
    display: block;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }

  &:hover {
    transform: scale(1.1);
  }
}

.swiper-button-next {
  right: calc(50% - 45px) !important;

  // &:hover {
  //   transform: translateX(5px);
  // }
}

.swiper-button-prev {
  left: calc(50% - 45px) !important;

  // &:hover {
  //   transform: translateX(-5px);
  // }
}

.swiper-pagination {
  text-align: center;
  width: 100% !important;
  bottom: -40px !important;
}

.swiper-pagination-bullet {
  transform: none !important;
  margin: 0 5px;
  background: white !important;

  &.swiper-pagination-bullet-active  {
    background: white !important;
  }
}

.swiper-slide {
  background-color: $background-color;

  &-prev, 
  &-next {
    @media screen and (max-width: 778px ) {
      opacity: 0 !important;
    }
  }
}

.slide-item-animate {
  transform: translateX(35px);
  opacity: 0;
  transition-duration: .4s;
  transition-timing-function: ease-out;
}

.swiper-slide-active {
  .slide-item-animate {
    opacity: 1;
    transform: none;
  }

  .testimonial {
    &__content__text {
      transition-delay: .3s;
    }

    &__avatar {
      transition-delay: .4s;
    }
  
    &__author__name {
      transition-delay: .5s;
    }

    &__author__title {
      transition-delay: .8s;
    }
  }
}