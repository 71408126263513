@import '../../assets/styles/variables.scss';
@import '../../assets/styles/breakpoints.scss';

.page-loader {
  background-color: $text;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  // height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-delay: 1.5s;
}

.logo-loader {
  position: absolute;
  width: 100px;

  svg {
    height: 100%;
    width: 100%;
  }
}