@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/breakpoints.scss';

.intro {
  display: flex;
  flex-direction: column;
  position: relative;
  
  @include breakpoint('desktop') {
    margin-left: 5%;
  }

  h2 {
    line-height: 1;
    font-size: 43px;
    margin-top: 60px;
    text-transform: uppercase;

    @include breakpoint('mobile') {
      font-size: 50px;
    }

    // position: absolute;
    // top: 0;
    // left: 0;

    // span {
    //   > div {
    //     > div {
    //       > div {
    //         transition: .3s;
    //         &:hover {
    //           // color :red;
    //           // vertical-align: text-top;
    //           // transform: translateY(5px);
    //         }
    //       }
    //     }
    //   }
    // }

    @include breakpoint('fablet') {
      font-size: 90px;
    }

    @include breakpoint('tablet') {
      font-size: 120px;
    }

    @include breakpoint('wide') {
      font-size: 160px;
    }
  }
}

.intro__header--copy {
  position: absolute;
  z-index: 1;
}

@keyframes morph {
  0% {border-radius: 42% 68% 70% 30% / 45% 45% 55% 55%;}
  25% {border-radius: 38% 58% 60% 36% / 48% 50% 60% 57%;}
  32% {border-radius: 38% 62% 65% 33% / 48% 50% 60% 57%;}
  50% {border-radius: 41% 62% 65% 32% / 46% 47% 57% 56%;}
  75% {border-radius: 36% 58% 60% 36% / 48% 50% 60% 57%;}
  85% {border-radius: 38% 60% 65% 34% / 46% 48% 58% 56%;}
  100% {border-radius: 42% 68% 70% 30% / 45% 45% 55% 55%;}
}

.intro__shape {
  position: absolute;
  // border-radius: 50%;
  border-radius: 39% 60% 72% 30% / 48% 48% 56% 54%;
  top: 0;
  left: 0;
  width: 220px;
  height: 220px;
  overflow: hidden;
  // Fix for Safari (otherwise overflow: hidden is not taking into account the border-radius)
  z-index: 1;
  transform: translate3d(0, 0, 0);
  animation: morph 7s linear infinite;

  @include breakpoint('tablet') {
    width: 40vw;
    height: 40vw;
  }

  @include breakpoint('wide') {
    width: 30vw;
    height: 30vw;
  }
}

.intro__name, .intro__iam {
  display: block
}

// .intro__name {
  // color: transparent;
  // -webkit-text-stroke: 2px white;
// }

.intro__header {
  position: relative;
  
  @include breakpoint('desktop') {
    min-height: 400px;
  }
}

.description {
  align-self: center;
  position: relative;
  padding: 30px;

  &__decoration {
    position: absolute;
    right: -5px;
    top: 10px;
    width: 100%;
    height: 100%;
    content: '';
    border: 1px solid;
    z-index: -1;

    @include breakpoint('tablet') {
      right: -15px;
    }
  }
  
  @include breakpoint('tablet') {
    padding: 40px 80px;
  }

  h1 {
    @include subtitle_text(24px);
    text-align: center;
    margin: 0;

    @include breakpoint('tablet') {
      text-align: left;
    }

    span {
      @include breakpoint('fablet') {
        display: block;
      }
    }
  }
}

.intro__greeting {
  position: relative;
  display: flex;
}

.dot-small {
  color: $dot;
}