@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/breakpoints.scss';

.footer {
  padding: 16px 0;
  border-top: 1px solid $light-grey;
  background-color: $background-color;
  position: relative;
  z-index: 1;

  .footer__details {
    letter-spacing: 1.5px;

    @include breakpoint('tablet') {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
      }
    }
  }

  .footer__social-links {
    display: flex;
  }

  .footer__social-link {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      svg {
        path {
          fill: white;
        }
      }
    }
    
    svg {
      width: 35px;
      height: 35px;
      margin-left: 10px;
      fill: white;
      border-radius: 50%;
      
      path {
        transition: .4s;
      }
    }
  }

}
