@import './mixins.scss';
@import './breakpoints.scss';
@import './variables.scss';

* {
  box-sizing: inherit;
  min-height: 0;
  min-width: 0;
  outline: 0;

  &::after,
  &::before {
    box-sizing: inherit;
  }
}

html {
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  text-rendering: optimizeLegibility;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  color: white;
  background-color: $background-color;
}

figure {
  margin: 0;
}

button {
  background-color: transparent;
  border: none;
  appearance: none;
  font-family: inherit;
}

h1 {
  color: white;
  font-weight: bold;
}

h2, h3 {
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  line-height: 1;
}

h3 {
  color: white;
  font-size: 45px;

  @include breakpoint('fablet') {
    font-size: 8.461538461538462vw;
  }

  @include breakpoint('wide') {
    font-size: 110px;
  }
}

ul {
  padding: 0;
  list-style: none;
}

main {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: white;
}

section {
  margin-bottom: 20%;
}

blockquote {
  margin: 0;
}

.section-title {
  margin-bottom: 20px;

  @include breakpoint('desktop') {
    margin-bottom: 40px;
  }
}

.overlay--opened {
  overflow-y: hidden;

  .overlay {
    pointer-events: all;
  }
}

.color-change {
  .project {
    :before,
    :after {
      display: none;
    }
  }
}

.text-right {
  text-align: right;
}

.upper-wrap {
  overflow: hidden;
  display: inline-block;
  position: relative;
}

.line {
  width: 100%;
  position: absolute;
  height: 1px;
  background: white;
  left: 0;
  bottom: 0;
  max-width: 700px;
  opacity: 0.8;
}

.dot {
  font-size: 100px;
  line-height: 0;

  @include breakpoint('tablet') {
    font-size: 17.543859649122805vw;
  }

  @include breakpoint('desktop') {
    font-size: 200px;
  }
}

.huge-text {
  position: absolute;
  font-size: 150px;
  font-family: 'Poppins', sans-serif;
  color: rgba(90, 90, 90, 0.1);
  font-weight: bold;
  display: none;
  pointer-events: none;

  @include breakpoint('fablet') {
    display: block;
  }

  @include breakpoint('tablet') {
    font-size: 17.857142857142858vw;
  }

  @include breakpoint('wide') {
    font-size: 250px;
  }

  span {
    display: block;
  }
}

.border {
  position: absolute;
  opacity: 0.8;
  background-color: $text;

  &.top-side {
    top: 0;
    height: 1px;
    left: 0;
    width: 0;
  }

  &.right-side {
    top: 0;
    height: 0;
    right: 0;
    width: 1px;
  }

  &.bottom-side {
    bottom: 0;
    height: 1px;
    right: 0;
    width: 0;
  }

  &.left-side {
    bottom: 0;
    height: 0;
    left: 0;
    width: 1px;
  }
}

.cookieConsent {
  > div {
    flex: 1 !important;
  }
}

.accent-font {
  font-family: 'Dancing Script', cursive;
  display: inline !important;
  font-size: 130%;
  line-height: 1px;
}

.site-nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 50;
  background-color: $background-color;
  border-bottom: 1px solid $light-grey;
  
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 16px;
    
    @include breakpoint('fablet') {
      justify-content: flex-end;
    }
  }
}

.nav-link {
  @include underline();
  padding: 0;
  
  &:not(:last-child) {
    margin-right: 20px;
  }
  
  > * {
    @include subtitle_text(16px);
    font-weight: normal;
    cursor: pointer;
    text-transform: lowercase;
    color: white;
    padding: 0;
  }
}

.container {
  margin: 0 auto;
  width: 90%;
  max-width: 1300px;
  position: relative;
}

.grid {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;

  .grid-line {
    position: relative;
    flex-basis: 0;
    border-right: 1px solid $light-grey;

    @include breakpoint('tablet') {
      flex-basis: 20%;
    }
  }
}
