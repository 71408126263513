@import './node_modules/swiper/css/swiper';
@import './assets/styles/global.scss';
@import './assets/styles/mixins.scss';
@import './assets/styles/breakpoints.scss';

body,
html {
  height: 100%;
}

.App {
  position: relative;
}

.logo {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 20px;
  left: 0;

  svg {
    width: 100%;
    height: 100%;
    fill: white;
  }
}

.credits {
  position: absolute;
  font-size: 14px;
  bottom: 20px;
  width: 100%;
  text-align: center;

  @include breakpoint('desktop') {
    bottom: 30px;
    text-align: right;
  }

  span {
    div {
      margin: 0 2px;
      display: inline-block;
    }
  }

  svg {
    height: 15px;
    width: 15px;
    fill: #bf3c57;
  }
}

.one-page-award {
  display: inline-block;
  position: fixed;
  top: 0;
  z-index: 60;
  right: 0;

  @include breakpoint('fablet') {
    left: 0;
    right: auto;
  }
}

.activate-color-change {
  position: fixed;
  right: 30px;
  top: 80px;
  z-index: 10;

  button {
    position: relative;
    font-family: 'Poppins';
    font-size: 10px;
    cursor: pointer;
    color: white;
    outline: none;
    padding: 8px;
    border-top-left-radius: 35%;
    border-bottom-right-radius: 35%;
    box-shadow: 0px 3px 10px rgba(10, 10, 10, .3);

    @include breakpoint('desktop') {
      font-size: 14px;
      padding: 16px;
    }

  }

  &__decoration {
    position: absolute;
    top: -3px;
    right: -3px;
    height: 100%;
    width: 100%;
    border-top-left-radius: 35%;
    border-bottom-right-radius: 35%;
    z-index: -2;
    transition: 0.2s ease-out;
  }

  &:hover {
    .activate-color-change__decoration {
      transform: translate(5px, -5px);
    }
  }
}

.cursor {
  position: fixed; // this needs to be fixed, so that the cursor stays in place on scroll
  width: 25px;
  height: 25px;
  border-radius: 50%;
  pointer-events: none;
  z-index: 999;
  mix-blend-mode: difference;
  background-color: white;
}