@import './variables.scss';

// Fonts
@import url("https://fonts.googleapis.com/css?family=Dancing+Script|Poppins:900|Open+Sans:300,400&display=swap");

// Mixins
@mixin unstyle-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin underline() {
  position: relative;

  .underline {
    transition: 0.3s ease-in;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    transform: scaleX(0);
  }
  
  &:hover {
    .underline {
      transform: scaleX(1);
    }
  }
}

@mixin subtitle_text($font-size) {
  font-weight: lighter;
  font-size: 15px;
  color: $text;
  line-height: 2;
  letter-spacing: 2px;

  @include breakpoint('fablet') {
    font-size: 2.2878932316491896vw;
  }

  @include breakpoint('desktop') {
    font-size: $font-size;
  }
}