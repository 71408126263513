@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/breakpoints.scss';


.skills {
  position: relative;

  @include breakpoint('fablet') {
    margin-bottom: 9%;
  }

  .huge-text {
    top: -25%;
    left: 40%;
  }
}

.skills__details {
  @include subtitle_text(20px);
  padding: 30px;
  display: inline-block;
  position: relative;
  width: 100%;

  @include breakpoint('fablet') {
    width: auto;
  }

  @include breakpoint('desktop') {
    padding: 50px 150px 50px 50px;
  }

  .cover {
    background-color: #1a1a1aed;
  }
}