@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/breakpoints.scss';

$hover-transition: .4s;

.contact {
  margin-bottom: 60px;

  @include breakpoint('fablet') {
    margin-bottom: 15%;
  }

  @include breakpoint('desktop') {
    margin-bottom: 10%;
    margin-top: 0;
  }

  @include breakpoint('wide') {
    margin-bottom: 10%;
  }

  a {
    @include subtitle_text(20px);
    display: inline-block;
    align-self: flex-end;
    margin-bottom: 5px;
    text-decoration: none;
    transition: color $hover-transition;

    &:hover {
      // color: $dot;

      .arrow {
        opacity: 1;
        transform: translateX(10px);
      }
    }
  }

  .arrow {
    opacity: 0;
    margin-right: 10px;
    display: inline-block;
    transition: $hover-transition;
    // color: $dot;
  }
}

.contact__title {
  h3 {
    display: inline-block;
  }
}

.contact__details {
  display: flex;
  flex-direction: column;
}