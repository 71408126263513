@import '../../assets/styles/global.scss';
@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/breakpoints.scss';

.overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
  
  @include breakpoint('tablet') {
    height: 100vh;
  }

  .container {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding: 14% 0;
    align-items: center;

    @include breakpoint('tablet') {
      padding: 0;
    }
  }
}
.overlay--not-firefox {
  clip-path: polygon(0.0001% 100%, 0.0001% 100%, 0.0001% 0.0001%, 0.0001% 0.0001%);
}

.overlay--firefox,
.overlay--ie {
  // transform:  translateX(-100%);
  opacity: 0;
}

.close-overlay-button {
  position: absolute;
  top: 20px;
  right: 0;
  color: black;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease-in;
  
  @include breakpoint('fablet') {
    display: block;
    right: -40px;
  }

  @include breakpoint('tablet') {
    font-size: 30px;
  }

  &:hover {
    transform: rotate(90deg);
  }

  > div {
    position: relative;
    width: 100px;
  }

  span {
    display: inline-block;
    position: absolute;
    left: 0;
  }
}

.about {
  width: 100%;
  color: $background-color;
  
  @include breakpoint('tablet') {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  h4 {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1.6;
    letter-spacing: 1px;
    margin-top: 0;
    font-weight: lighter;
    
    @include breakpoint('fablet') {
      font-size: 16px;
    }
    
    @include breakpoint('desktop') {
      font-size: 22px;
      margin-bottom: 20px;
    }

    span {
      display: block;
    }

    a {
      @include underline();
      color: inherit;
    }
  }

  .about__contact {
    display: flex;
    align-items: center;
    align-self: baseline;
    overflow: hidden;
    
    a {
      @include underline();
      color: $background-color;
      font-size: 14px;
      letter-spacing: 1px;
      display: block;
      margin-left: 15px;
      transition: 0.4s;
      position: relative;
      padding-bottom: 5px;
      font-weight: lighter;

      @include breakpoint('fablet') {
        font-size: 16px;
      }
      
      @include breakpoint('desktop') {
        font-size: 20px;
      }
    }

    svg {
      height: 20px;
      width: 20px;
      fill: $background-color;

      @include breakpoint('desktop') {
        height: 25px;
        width: 25px;
      }
    }

    &:hover {
      .about__contact-icons {
        > div:last-child {
          opacity: 1;
          transform: rotate(0deg);
        }

        > div:first-child {
          opacity: 0;
          transform: rotate(90deg);
        }
      }
    }
  }

  .about__contact-icons {
    position: relative;

    > div {
      transition: 0.4s;
    }

    > div:first-child {
      position: absolute;
    }
    
    > div:last-child {
      opacity: 0;
      transform: rotate(-90deg);
    }
  }
}

.about__left {
  @include breakpoint('tablet') {
    width: 55%;
  }
}

.about__right {
  max-width: 65vw;
  margin: 5% auto 0 auto;
  order: 1;

  @include breakpoint('tablet') {
    order: 2;
    max-width: none;
    margin: 0;
    width: 40%;
    display: block;
  }

  .distortion {
    height: 400px;
  }

  .avatar {
    // cursor: pointer;
    position: relative;
    background-size: cover;
    background-position: center;
    padding-top: 73%;
    border-top-left-radius: 35%;
    border-bottom-right-radius: 35%;

    // decoration line
    &::before {
      position: absolute;
      content: '';
      top: -5px;
      height: 100%;
      width: 100%;
      right: -25px;
      border: 1px solid;
      opacity: 0.3;
      border-top-left-radius: 35%;
      border-bottom-right-radius: 35%;
      z-index: -1;

      @include breakpoint('tablet') {
        top: -20px;
        right: -35px;
  
      }
    }

    // decoration color outline
    .avatar__decoration {
      position: absolute;
      top: -12px;
      right: -20px;
      height: 100%;
      width: 100%;
      border-top-left-radius: 35%;
      border-bottom-right-radius: 35%;
      z-index: -2;

      @include breakpoint('tablet') {
        top: -30px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.6;
      border-top-left-radius: 35%;
      border-bottom-right-radius: 35%;
    }
  }
}


@keyframes heartAnimation {
  0% { transform: scale(1)}
  50% { transform: scale(1.2) }
  100% { transform: scale(1.1) }
}

.heart-icon {
  animation: heartAnimation 1.5s infinite;
}

@keyframes animate-triangle-top {
  0%   { transform: scale(1)   translate(0vw, 0vh); }
  20%  { transform: scale(1.5, 2) translate(10vw, 10vh) rotate(60deg); }
  40%  { transform: scale(1.5, 2) translate(20vw, 12vh) rotate(60deg); }
  60%  { transform: scale(1.5) translate(25vw, 12vh) rotate(20deg); }
  80%  { transform: scale(1.8) translate(20vw, 10vh) rotate(20deg); }
  100% { transform: scale(1)   translate(0vw, 0vh); }
}

@keyframes animate-triangle-bottom {
  0%   { transform: scale(1)   translate(0vw, 0vh); }
  15%  { transform: scale(1.5, 2) translate(-10vw, -10vh) rotate(-60deg); }
  30%  { transform: scale(1.5, 2) translate(-20vw, -12vh) rotate(-60deg); }
  60%  { transform: scale(1.5) translate(-25vw, -12vh) rotate(-20deg); }
  90%  { transform: scale(1.8) translate(-20vw, -10vh) rotate(-20deg); }
  100% { transform: scale(1)   translate(0vw, 0vh); }
}