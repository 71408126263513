@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/breakpoints.scss';


.triangle-shape {
  position: absolute;
  z-index: -1;
  display: none;
  // opacity: 0.9;

  @include breakpoint('fablet') {
    flex-basis: 20%;
    display: block;
  }

  svg {
    fill: transparent;
  }
}